<template>
  <div class="Userhl_mezp Userhl_rightbox">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="我参与的活动" name="first">
        <ul class="Userhl_mezpul">
          <li
            class="Userhl_mezpulli"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="Userhl_mezpullil">
              <img :src="item.title_pic" alt="" @click="zhanping(item.a_id)" />
            </div>
            <div class="Userhl_mezpullir">
              <p><span>活动名称</span>{{ item.title }}</p>
              <p>
                <span>活动时间</span>{{ item.draw_start }}-{{ item.draw_end }}
              </p>
              <p v-show="item.address!=''?true:false"><span>活动地点</span>{{ item.address }}</p>
              <p v-if="false"><span>活动类型</span>{{ item.a_type }}</p>
              <p><span>收费类型</span>{{ item.pay_state }}</p>
              <p>
                <span>活动费用</span>{{ item.money > 0 ? item.money : "免费" }}
              </p>
            </div>
            <div class="Userhl_mezpullimoney">
              <img
                src="https://task.hozoin.cn/Works/20210112/sj-9232138810.png"
                alt=""
              />
              <span>￥{{ item.money > 0 ? item.money : "免费" }}</span>
            </div>
          </li>
          <li class="lizero" v-if="lizero">
            <img
              src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
              alt=""
            />
          </li>
        </ul>
      </el-tab-pane>
      <el-tab-pane label="我发起的活动" name="two">
        <ul class="Userhl_mezpul Userhl_mezpulfq">
          <li
            class="Userhl_mezpulli"
            style="height: 27rem"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="Userhl_mezpullil" style="height: 20rem">
              <div class="Userhl_mezpullil_hdsh">{{item.a_state=='1'?'已上线':'待审核'}}</div>
              <img :src="item.title_pic" alt="" @click="zhanping(item.a_id)" />
            </div>
            <div class="Userhl_mezpullir" style="height: 20rem">
              <p><span>活动名称</span>{{ item.title }}</p>
              <p>
                <span>活动时间</span>{{ item.draw_start }}-{{ item.draw_end }}
              </p>
              <p v-show="item.address!=''?true:false"><span>活动地点</span>{{ item.address }}</p>
              <p v-if="false"><span>活动类型</span>{{ item.a_type }}</p>
              <p><span>收费类型</span>{{ item.pay_state }}</p>
              <p>
                <span>活动费用</span>{{ item.money > 0 ? item.money : "免费" }}
              </p>
            </div>
            <el-button class="Userhl_mezpullick" @click="ckbm(item.a_id)"
              >查看报名情况</el-button
            >
            <div class="Userhl_mezpullibottom">
              <div>报名人数：{{ item.signNum }}</div>
              <div>报名总收入：￥{{ item.money }}</div>
            </div>
          </li>
          <li class="lizero" v-if="lizero">
            <img
              src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
              alt=""
            />
          </li>
        </ul>
        <el-dialog
          title="报名情况"
          :visible.sync="dialogVisible"
          width="30%"
        >
          <el-table :data="bmall" border style="width: 100%" stripe>
            <el-table-column prop="username" label="联系人" style="">
            </el-table-column>
            <el-table-column prop="phone" label="联系电话" style="">
            </el-table-column>
            <el-table-column
              prop="create_time"
              label="报名时间"
            ></el-table-column>
          </el-table>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dialogVisible = false;bmall=[]"
              >确 定</el-button
            >
          </span>
        </el-dialog>
      </el-tab-pane>
    </el-tabs>
    <el-pagination
      :pager-count="5"
      @current-change="currentchange_gz"
      :page-size="limit"
      background
      layout="prev, pager, next"
      :total="total_gz"
    >
    </el-pagination>
    <el-button class="linkbtn" plain @click="linkxm('/Zhanping')">查看更多活动</el-button>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      lizero: false,
      activeName: "first",
      img: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      dialogVisible: false,
      tableData: [],
      list: [],
      type: "myTakePart",
      limit: 10,
      total_gz: 0,
      bmall: [],
      userinfo: {},
    };
  },
  methods: {
    linkxm(link) {
      this.$router.push({ path: link });
    },
    handleClick(tab, event) {
      var indexid = tab.index;
      if (indexid == 0) {
        this.type = "myTakePart";
      } else {
        this.type = "myInitiate";
      }
      this.axiosqq(1);
    },
    axiosqq(id) {
      var that = this;
      this.lizero = true;
      that.list = [];
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/" + that.type + "",
        method: "post",
        data: {
          page: id,
          limit: that.limit,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          this.lizero = false;
          that.total_gz = res.data.result.count;
          that.list = res.data.result.list;
          for (var i = 0; i < that.list.length; i++) {
            if (that.list[i].a_type == 1) {
              that.list[i].a_type = "沙龙";
            } else if (that.list[i].a_type == 2) {
              that.list[i].a_type = "竞赛";
            } else if (that.list[i].a_type == 3) {
              that.list[i].a_type = "展览";
            }
            if (that.list[i].pay_state == 0) {
              that.list[i].pay_state = "线下付款";
            } else if (that.list[i].pay_state == 1) {
              that.list[i].pay_state = "线上付款";
            }
          }
        } else {
          this.lizero = true;
        }
      });
    },
    currentchange_gz(index) {
      this.axiosqq(index);
    },
    ckbm(a_id) {
      var that = this;
      that.dialogVisible = true;
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/myActivitySign",
        method: "post",
        data: {
          page: 1,
          limit: 1000,
          a_id,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.bmall = res.data.result.list;
        } else {
        }
      });
    },
    zhanping(id) {
      this.$router.push({ path: "/shejihd_c", query: { data: id } });
    },
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    this.axiosqq(1);
  },
  watch: {},
};
</script>
